// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-connectivity-jsx": () => import("./../../../src/pages/connectivity.jsx" /* webpackChunkName: "component---src-pages-connectivity-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-innovation-jsx": () => import("./../../../src/pages/innovation.jsx" /* webpackChunkName: "component---src-pages-innovation-jsx" */),
  "component---src-pages-milk-systems-jsx": () => import("./../../../src/pages/milk-systems.jsx" /* webpackChunkName: "component---src-pages-milk-systems-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-machine-jsx": () => import("./../../../src/templates/machine.jsx" /* webpackChunkName: "component---src-templates-machine-jsx" */)
}

